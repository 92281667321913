*:before,
*:after,
* {
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: -apple-system, 'Helvetica Neue', sans-serif;
  font-weight: bold;
  font-size: 24px;
  color: #DCE0D9;
  background-color: #000;
  margin: 0;
}

input,
textarea {
  -webkit-font-smoothing: antialiased;
  font: inherit;
  color: #DCE0D9;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
}

.page {
  min-height: 100vh;
  padding: 5vh 2rem;
  display: flex;
  flex-direction: column;
}

.header {
  line-height: 50px;
}

/* utils */
.block {
  display: block;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.search {
  margin: auto;
}

.title {
  margin: 0;
  font-size: 24px;
}

.input {
  background: none;
  font-size: 72px;
  width: calc(100vw - 4rem);
  border: none;
  outline: none;
  text-align: center;
  padding: 0;
}

.input::placeholder {
  color: #323232;
}

.grid {
  display: grid;
  place-items: center;
}

.grid-item {
  grid-area: 1/1;
}

.user-hint {
  text-align: center;
  font-size: 24px;
  line-height: 38px;
  min-height: 38px;
}

.video {
  background-color: #000;
  display: block;
  width: 100%;
  max-width: 480px;
  max-height: 480px;
  box-shadow: 0 0 40px 0px rgba(0, 0, 0, 0.68);
  opacity: 0;
  transform: scale(0) rotate(0deg);
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  outline: 1px solid transparent;
}

.loaded {
  opacity: 1;
  transform: scale(1) rotate(0deg);
}

.loaded:nth-of-type(2n) {
  transform: scale(1) rotate(5deg);
}

.loaded:nth-of-type(3n) {
  transform: scale(1) rotate(-5deg);
}

.loaded:nth-of-type(4n) {
  transform: scale(1) rotate(7deg);
}

footer {
  color: #5c5c5c;
  font-size: 1rem;
  text-align: center;
  position: fixed;
  bottom: 24px;
  right: 24px;
}

footer a:default,
footer a:visited {
  color: #5c5c5c;
}

.logo:hover path {
  fill: #DCE0D9;
}

@media (max-width: 480px) {
  .user-hint,
  .title {
    font-size: 18px;
  }
  .page {
    padding: 2rem;
  }
  .input {
    font-size: 36px;
  }
}